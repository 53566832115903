import axios from 'axios';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import JwtService from '@/core/services/JwtService';
import UrlService from '@/core/services/UrlService';
import AlertService from '@/core/services/AlertService';

const InviteModule = {
   namespaced: true,
   state: {},
   mutations: {},
   actions: {
      checkUserBeforeAccept({ commit, state, dispatch }, params) {
         return new Promise((resolve, reject) => {
            if (params?.email) params.email = params.email.replaceAll(' ', '+');
            
            axios
               .post('user/auth/check-user-before-accept', params)
               .then((res) => {
                  const { data } = res;

                  if (data.code == 200) {
                     //User have account
                     //Company attached
                     JwtService.setSharedDomainsCookie({ token: data.result.token });

                     commit(Mutations.SET_TOKEN, data.result.token);
                     let company = data.result.companies?.find(
                        (el) => el?.tenants?.name === params.subdomain,
                     );
                     dispatch(Actions.SET_COMPANY, company, { root: true });
                     UrlService.redirectWithSubdomain();
                  } else if (data.code == 100) {
                     //User not have account
                     console.log('params', params);
                     let extraData = '';
                     if (params.employee === '1') {
                        extraData =
                           '&employee=' +
                           params.employee +
                           '&employee_name=' +
                           params?.employee_name +
                           '&phone=' +
                           params?.phone;
                     }
                     UrlService.redirectWithoutSubdomain(
                        '/#/user-complete-profile?subdomain=' +
                           data.result.subdomain +
                           '&email=' +
                           data.result.email +
                           '&token=' +
                           data.result.token +
                           extraData,
                     );
                  } else {
                     UrlService.redirectWithoutSubdomain('/#/sign-in');
                  }

                  resolve(res);
               })
               .catch((error) => {
                  AlertService.errorText('Your invitation has expired').then(() =>
                     UrlService.redirectWithoutSubdomain('/#/sign-in'),
                  );
                  reject(error);
               });
         });
      },
   },
};
export default InviteModule;
